<template>
  <v-app>
    <!-- <router-view name="headernav"></router-view> -->
    <header-component></header-component>
    <v-main>
      <router-view></router-view>
    </v-main>
    <!-- <router-view name="footer"></router-view> -->
    <footer-component></footer-component>
  </v-app>
</template>

<script>

import FooterComponent from './components/FooterComponent.vue'
import HeaderComponent from './components/HeaderComponent.vue'

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  }
}
</script>

<style lang="scss">


</style>
