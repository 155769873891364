import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from './default-preset/preset'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#72A956',
        primaryAccent: '#EEF5EB',
        secondary: '#3079BE',
        subsecondary: '#EFF4FA',
        subsscondary: '#20507E',
        titlesecondary: '#F9F8FD',
        accent: '#171717',
        lightaccent: '#F7F7F7',
        lightsecondary: '#F6F9FF',
        accentligth: '#76737A',
        secondaryAccent: '#FFFFFF',
        error: '#b71c1c',
        lightError: '#F7E5E5',
        // ErrorAccent: '#F7E5E5',
        // subprimary: '#CBDEEE',
        subprimary: '#EEF5EB',
        subaccent: '#EBEBEB',
        // F4F4F4
        // E9ECEF

        orange: '#9C27B0',
        teal: '#009688',
        blue: '#2196F3'
      },
    },
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
