import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    initsubcription: {
      init: false,
      data: ''
    },
    // pricingSub

    subscribtion: {
      pricing: {
        isSubscribed: false,
        level: 0,
        title: "Basic"
      },
      user: {}
    },

    user: {
      id: '',
      email: '',
      skills: []
    },
    userlogo: '',

    api_key: '',
    // token: localStorage.getItem('token') || undefined,
    token: localStorage.getItem('token'),
    actived: false,

    // defined temp t_ids
    tids: -1,

    service: {
      id: '',
    },
    company: {
    },
    viewrequest: {
      inbox: true,
      owner: 'user',
      type: '',
      id: ''
    },

    // utilisateur temporaire 
    colaboration: {
      initialized: false,
      owner: '',
      name: '',
      recipient: ''
    },
    initactivcode: {
      init: false
    },


    /**
     * Check account type
     */
    checkaccount: false,
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    },

    logout(state) {
      // localStorage.removeItem('setUser')
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('company')
      state.token = ''
      state.company = {}
      state.user = {}
    },
    /**
     * mutate check account method
     * @param {*} state 
     * @param {*} value 
     */
    setCheckaccount(state, value) {
      state.checkaccount = value
    },

    /**
     * Initsubscription
     * @param {*} state 
     * @param {*} value 
     */
    setInitSubscription(state, value) {
      // console.log(value)
      state.initsubcription = value
    },

    setInitActivationCode(state, value) {
      state.initactivcode = value
    },

    // Set user subscribtion
    setSubscribtion(state, value) {
      state.subscribtion.pricing = value,
        state.subscribtion.user = state.user
    },

    // 
    setTids(state, value) {
      state.tids = value
    },

    setService(state, value) {
      state.service = value
    },

    deletecolaboration(state) {
      state.colaboration = {}
    },

    setColaboration(state, colaboration) {
      state.colaboration = colaboration
    },
    setuserlogo(state, photo) {
      localStorage.setItem('photo', JSON.stringify(photo));
      state.userlogo = photo
      console.log(state.userlogo)
    },
    setUser(state, user) {
      localStorage.setItem('user', JSON.stringify(user));
      state.user = user
    },
    setToken(state, token) {
      localStorage.setItem('token', JSON.stringify(token));
      state.token = token;
    },
    setCompanyId(state, id) {
      state.id = id
    },

    setUserId(state, id) {
      state.user.id = id
    },
    setApiToken(state, value) {
      state.api_key = value;
    },
    // setUserAbout(state, about) {
    //   localStorage.setItem('user', JSON.stringify(user));
    //   state.user.about = about
    // },
    // setUserSkill(state, skill) {
    //   localStorage.setItem('user', JSON.stringify(user));
    //   state.user.skills = skill
    // },

    setCompany(state, company) {
      state.company = company
    },

    setViewrequest(state, request) {
      state.viewrequest = request
    },
    setValid(state, value) {
      state.actived = value;
    },
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('company')
        resolve()
        reject()
      })
    },

    deletecolaboration({ commit }) {
      return new Promise((resolve, reject) => {
        commit('deletecolaboration')
        localStorage.removeItem('colaboration')
        resolve()
        reject()
      })
    },
  },
  getters: {
    // isLoggedIn: state => !!state.token, // && state.actived
    isLoggedIn: state => {
      if ((state.token !== null) && (state.token !== '') && (state.token !== undefined)) {
        return true
      }
      else {
        return false
      }
    },


    /**
     * Accessor check account method
     * @param {*} state 
     * @returns 
     */

    isDoubleAccount: state => {
      return state.checkaccount
    },

    getInitSubscription: state => {
      return state.initsubcription
    },

    getInitActivationCode: state => {
      return state.initactivcode
    },

    // Get user subscription information
    isSubscribed: state => {
      return state.subscribtion.pricing.isSubscribed
    },
    getSubscribtionLevel: state => {
      return state.subscribtion.pricing.level
    },
    getSubscribtion: state => {
      return state.subscribtion
    },

    getColaboration: state => {
      return state.colaboration
    },

    getViewrequest: state => {
      return state.viewrequest
    },

    getUserId: state => {
      return state.user.id
    },
    getuserlogo: state => {
      console.log(state.userlogo)

      return state.userlogo
    },
    //
    getTids: state => {
      return state.tids
    },

    getService: state => {
      return state.service
    },

    getToken: state => {
      return state.token
    },
    getApiKey: state => {
      return state.api_key
    },
    getUser: state => {
      return state.user
    },
    getUserSkill: state => {
      return state.user.skills
    },
    getCompany: state => {
      return state.company
    },

    getCompanyId: state => {
      return state.company.id
    },
  }
})
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state));
});

export default store;
