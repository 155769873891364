<template>
  <div class="header-pgdash-wrapper mb-4">
    <v-img src="@/assets/images/bgs/person-brainst.svg" aspect-ratio="1.7" class="header-pgdash">
    <div class="header-pgdash-bg">
      <div class="mx-auto text-center">
        <h1>
          <span class="white--text text-">{{ header.titlepage }}</span>
        </h1>
      </div>
    </div>
    </v-img> 
  </div>
</template>

<script>
import { mdiDomain, mdiAccountNetwork } from '@mdi/js';
export default {
  props: {
    header: {
      type: Object,
      default: () => { }
    },
    imgob: null
  },
  data() {
    return {
      links: {
        colaboration: {
          title: 'Colaborators',
          description: 'Follow and colaborate with companies and professionnals',
          color: 'secondary',
          img: '@/assets/images/bgs/person-docs.png'
        },
        proposal: {
          title: 'Proposals',
          description: 'Send offers to specific users',
          color: 'blue'
        },
        service: {
          title: 'Services',
          description: 'Browse all services and products offered by companies',
          color: 'teal'
        },
        vacancies: {
          title: 'Vacancies',
          description: 'Search and browse recent job opportunities',
          color: 'primary'
        },
        blog: {
          title: 'Blog',
          description: 'Communicate effectively by publishing products and reviews for businesses and professionals.',
          color: 'primary'
        },
        chat: {
          title: 'Chat',
          description: 'Communicate in real time with your professionals and companies network.',
          color: 'primary'
        },
      },
      icons: { mdiDomain, mdiAccountNetwork }
    }
  },

}
</script>

<style lang="scss" scoped>
.header-pgdash-wrapper {
  height: 15em;
  width: 100%;
  text-shadow: 8px 8px 10px #0000008c;
  /*
  background-color: var(--v-secondary-base);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.25' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), linear-gradient(to right top, #343a40, #2b2c31, #211f22, #151314, #000000);
  background-repeat: repeat;
  */
  /*background-repeat: repeat;
  background-image: linear-gradient(45deg, transparent 20%, var(--v-secondary-base) 25%, transparent 25%),
    linear-gradient(-45deg, transparent 20%, var(--v-primary-base) 25%, transparent 25%),
    linear-gradient(-45deg, transparent 75%, var(--v-secondary-base) 80%, transparent 0),
    radial-gradient(var(--v-secondary-base) 2px, transparent 0);
  background-size: 30px 30px, 30px 30px; */
  position: relative;
}

.header-pgdash-wrapper,
.header-pgdash,
.header-pgdash-bg {
  border-radius: 0.8em;
}

.header-pgdash {
  position: relative;

  width: 100%;
  height: 100%;

}

.header-pgdash-bg {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: -webkit-linear-gradient(90deg, var(--v-primary-base) -30%, rgba(72, 149, 236, 0.651) 100%);

  //background: linear-gradient(rgba(48, 121, 190, 0.08), rgba(114, 169, 86, 0.08)), url('~@/assets/images/bgs/person-docs.png') center center / cover no-repeat;
  //background: linear-gradient(rgba(48, 121, 190, 0.08), rgba(114, 169, 86, 0.08));
}
</style>
