<template>
  <!-- <v-footer extended short class="px-0"> -->
  <v-footer extended class="px-0">
    <v-container fill-height fluid class="py-4">
      <v-row align="center" justify="center" class="d-flex flex-row flex-wrap mx-auto">
        <v-col cols="9" md="3" class="mx-auto">
          <div class="mx-auto">
            <router-link to="/" class="text-decoration-none">
              <v-img :src="require('@/assets/images/logos/logo.svg')" width="15em" alt="logo" contain>
              </v-img>
            </router-link>
          </div>
        </v-col>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical inset class="mx-4 mb-4">
        </v-divider>
        <v-col cols="9" md="4">
          <v-form class="multi-col-validation">
            <v-row>
              <v-col cols="12">
                <h4>Newsletter</h4>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <v-text-field v-model="n_letter_firstname" label="First Name" outlined dense placeholder="First Name"
                  hide-details>

                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="n_letter_lastname" label="Last Name" outlined dense placeholder="Last Name"
                  hide-details>
                </v-text-field>
              </v-col> -->
              <v-col cols="12" md="6">
                <v-text-field v-model="nletterEmail" outlined dense :hide-details="nletterEmailErrors.isvalid"
                  label="Email adress" placeholder="john@example.com" type="email"
                  :error-messages="nletterEmailErrors.value" @input="$v.nletterEmail.$touch()" @blur="$v.nletterEmail.$touch()"
                  clearable>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn color="primary" :loading="loading_nletter" block @click="submitNewsLetter">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

        </v-col>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical inset class="mx-4 mb-4"></v-divider>
        <v-col cols="9" md="3" class="bottom-contact mx-auto">
          <div class="">
            <v-icon small aria-hidden="false" color="primary" class="me-4">
              {{ icons.mdiMapMarkerOutline }}
            </v-icon>
            <span>824 Chappelle TKC, Yaounde</span>
          </div>

          <div class="">
            <v-icon small aria-hidden="false" color="primary" class="me-4">
              {{ icons.mdiPhoneOutline }}
            </v-icon>
            <span>(+237) 698966798 </span>
          </div>

          <div class="">
            <v-icon small aria-hidden="false" color="primary" class="me-4">
              {{ icons.mdiEmailOutline }}
            </v-icon>
            <span>contact@dyulaba.org</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="w-full py-4 px-6 secondary footer-sub-row">
      <div class="mx-6 d-flex justify-space-between white--text">
        <v-btn fab absolute bottom center class="btn-bottom-to-top" @click="$vuetify.goTo(target, options)">
          <v-icon aria-hidden="false" size="1.4em" color="primary" align="center" justify="center">
            {{ icons.mdiAppleKeyboardControl }}
          </v-icon>
        </v-btn>
        <span>
          Copyright &copy; 2022 AfroSkills. All Rights reserved
          <!-- | Developped by <a href="/" class="white--text text-decoration-none" target="_blank">Spacekola</a> -->
        </span>
        <span class="d-sm-inline d-none">
          <a href="/" target="_blank" class="me-6 text-decoration-none white--text">Privacy Policy</a>
          <a href="/" target="_blank" class="me-6 text-decoration-none white--text">Terms & Conditions</a>
          <a href="/" target="_blank" class="text-decoration-none white--text">FAQ</a>
        </span>
      </div>
    </div>
  </v-footer>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiAppleKeyboardControl, mdiMapMarkerOutline, mdiEmailOutline, mdiPhoneOutline
}
  from '@mdi/js'
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import { Drequest } from '@/plugins/Drequest'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  validations: {
    n_letter_firstname: { required, maxLength: minLength(3) },
    n_letter_lastname: { required, maxLength: maxLength(3) },
    nletterEmail: { required, email },
  },
  data() {
    return {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
      easings: Object.keys(easings),
      target: 5,
      nletterEmail: '',
      loading_nletter: false
    }
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      }
    },

    nletterEmailErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.nletterEmail.$dirty) return errors
      !this.$v.nletterEmail.email && errors.value.push('Must be valid e-mail') && (errors.isvalid = false)
      !this.$v.nletterEmail.required && errors.value.push('E-mail is required') && (errors.isvalid = false)
      return errors
    },
  },

  methods: {
    checkValidationEmail() {
      if (this.nletterEmailErrors.isvalid) {
        return true
      }
    },
    submitNewsLetter() {
      this.$v.$touch()
      if (this.checkValidationEmail()) {
        this.loading_nletter = true
        // console.log(this.nletterEmail)
        this.$v.$reset()
        this.nletterEmail = ""
      }

      this.loading_nletter = false
    }
  },
  setup() {

    const icons = {
      mdiAppleKeyboardControl, mdiMapMarkerOutline, mdiEmailOutline, mdiPhoneOutline
    }
    return {
      icons
    }
  },
}
</script>

<style lang="scss" scoped>
.footer-sub-row {
  position: relative;
  //border: 5px solid #000;
}

.btn-bottom-to-top {
  height: 3.6em !important;
  width: 3.6em !important;
  display: block;
  position: absolute;
  top: -1.9em;
  left: 48%;
  text-align: center;
  border: 1px solid var(--v-primary-base);
}

.bottom-contact {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.border {
  border: 1px solid #000;

}
</style>
