<template>
  <v-list-item class="vertical-nav-menu-link" v-bind="$attrs" active-class="bg-gradient-primary">
    <v-list-item-icon>
      <v-icon small :class="{ 'alternate-icon-small': !icon }">
        {{ icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title class="menu-title-item">
      {{ title }}
    </v-list-item-title>
  </v-list-item>
  <!-- <router-link :to="link">
    <div class="vertical-nav-menu-link" v-bind="$attrs" active-class="bg-gradient-primary">
      <v-icon small :class="{ 'alternate-icon-small': !icon }">
        {{ icon || alternateIcon }}
      </v-icon>
      <span class="menu-title-item hidden-sm-and-down">
        {{ title }}</span>
    </div>
  </router-link> -->

</template>

<script>
import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconsize: {
      type: String,
      default: 'large'
    },
    link: {
      type: Object,
      default: undefined,
      required: false,
    }
  },
  setup() {
    return {
      alternateIcon: mdiCheckboxBlankCircleOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/styles/styles.sass';


.vertical-nav-menu-link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: var(--v-secondary-base) !important;
  margin: 0px 1em;
  //padding: 0.4rem 0.8rem;
  cursor: pointer;
  justify-content: center;
  vertical-align: center;
  align-content: center;
  justify-items: center;
  align-items: center;

  .v-icon {
    color: var(--v-secondary-base) !important;
  }

  .menu-title-item {
    font-size: 11pt;
    margin-left: 0.4rem;
    background: transparent !important;
    background-color: transparent !important;
  }

  .alternate-icon-small {
    font-size: 16px;
    color: var(--v-secondary-base) !important;
  }
}

.vertical-nav-menu-link:hover {
  // border-radius: 1em !important;
  background: var(--v-subsecondary-base) !important;
  color: var(--v-secondary-base) !important;
  transition: 1s;

  @include elevationTransition();

  .alternate-icon-small {
    font-size: 16px;
    color: var(--v-secondary-base) !important;
  }

  .v-icon {
    color: var(--v-secondary-base) !important;
  }


}

.bg-gradient-primary {
  color: var(--v-secondary-base) !important;
  background: var(--v-subsecondary-base) !important;
  // border-radius: 1em !important;
}
</style>
