<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content"
    transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="primary" overlap offset-x="12" offset-y="12" dot>
        <v-img :src="require(lang.img)" v-bind="attrs" v-on="on" max-width="1em" alt="fr" class="">
        </v-img>
      </v-badge>
    </template>

    <!-- transition="slide-y-transition" -->
    <v-list-item link>
      <v-list-item-content>
        <v-list-item-title @click="switchlang(lang.lang)">{{ lang.lang }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-menu>
</template>

<script>

import {
} from '@mdi/js'

export default {
  data() {
    return {
      en: {
        'lang': "Fr", img: '@/assets/images/avatars/fr.png',
      },
      fr: {
        'lang': "Fr", img: '@/assets/images/avatars/fr.png',
      },
      lang: {
        'lang': "Fr", img: '@/assets/images/avatars/fr.png',
      },
    }
  },
  methods: {
    switchlang(language) {
      if (language === 'Fr') {
        this.lang = this.en
      }
      else {
        this.lang = this.fr
      }
    }
  },
  setup() {
    return {
      icons: {
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
