<template>
    <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content"
        transition="scroll-y-transition">
        <template v-slot:activator="{ on, attrs }">

            <v-icon v-bind="attrs" v-on="on" color="primary">{{ icons.mdiBell }}</v-icon>

        </template>
        <!-- transition="slide-y-transition" -->
        <v-list>
            <!-- Profile -->
            <v-list-item link>
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiAccountOutline }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title >Invitations</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-badge inline color="primary" content="2">
                    </v-badge>
                </v-list-item-action>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <!-- Offers -->
            <v-list-item link>
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiEmailOutline }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title >RFP</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-badge inline color="primary" content="2">
                    </v-badge>
                </v-list-item-action>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <!-- Settings -->
            <v-list-item link>
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiCogOutline }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>RFC</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-badge inline color="primary" content="2">
                    </v-badge>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>

import {
    mdiAccountOutline,
    mdiEmailOutline,
    mdiCheckboxMarkedOutline,
    mdiChatOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
    mdiBell
} from '@mdi/js'

export default {
    methods: {
        navigateToLink(value) {
            this.$router.push({ name: value.toString() })
        },
        logoutUser: function () {
            const promise = this.$store.dispatch('logout');
            promise.then((res) => {
                window.console.log(res);
                this.$router.push('/');
            })
                .catch(err => {
                    window.console.log(err)
                })
            //this.loading = false
        },
    },
    setup() {
        const icons = {
            mdiAccountOutline,
            mdiEmailOutline,
            mdiCheckboxMarkedOutline,
            mdiChatOutline,
            mdiCogOutline,
            mdiCurrencyUsd,
            mdiHelpCircleOutline,
            mdiLogoutVariant,
            mdiBell
        }
        return {
            icons,
        }
    },
}
</script>

<style lang="scss">
.user-profile-menu-content {
    .v-list-item {
        min-height: 2.5rem !important;
    }
}
</style>
