<template>
    <canvas ref="refidchart"></canvas>
</template>

<script>
import Chart from 'chart.js'

export default {
    name: 'CardLinearChart',

    props: {
        datasheet: {
            type: Array(),
            default: () => {}
        }
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            const ctx = this.$refs.refidchart.getContext('2d');
            const chart = new Chart(ctx, {
                type: 'line',
                data: this.datasheet,
                options: {
                    responsive: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }
    }
}
</script>