<template>
  <v-navigation-drawer app :value="isDrawerOpen" width="250" class="ml-auto" :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)">
    <div class="logo-nav-drawer">
      <router-link to="/home" class="text-decoration-none">
        <v-img :src="require('@/assets/images/logos/logo.svg')" max-width="7em" width="7em" alt="Duylaba"
          class="border-1">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-skeleton-loader type="image" height="100%" width="100%" class="mx-auto">
              </v-skeleton-loader>
            </v-row>
          </template>
        </v-img>
      </router-link>
    </div>
    <div class="spacer-nav-menu">
      <div v-if="isNotCompany">
        <nav-menu-section-title :title="''">
      </nav-menu-section-title>

        <!-- <nav-menu-link :title="user_navdrawer.home.title" :to="{ name: user_navdrawer.home.link }"
          :icon="user_navdrawer.home.icon" target="">
        </nav-menu-link> -->

        <nav-menu-link :title="user_navdrawer.account.title" :to="{ name: user_navdrawer.account.link }"
          :icon="user_navdrawer.account.icon" target="" class="rounded-lg">
        </nav-menu-link>

        <nav-menu-link :title="user_navdrawer.vacancies.title" :icon="user_navdrawer.vacancies.icon"
          :to="{ name: user_navdrawer.vacancies.link }" target=""></nav-menu-link>
        <!-- <nav-menu-link :title="user_navdrawer.rfc.title" :icon="user_navdrawer.rfc.icon"
          :to="{ name: user_navdrawer.rfc.link }" target="">
        </nav-menu-link> -->
        <nav-menu-link :title="user_navdrawer.join.title" :to="{ name: user_navdrawer.join.link }"
          :icon="user_navdrawer.join.icon" target="">
        </nav-menu-link>

        <nav-menu-link :title="user_navdrawer.event.title" :to="{ name: user_navdrawer.event.link }"
          :icon="user_navdrawer.event.icon" target="" class="rounded-lg">
        </nav-menu-link>

        <nav-menu-link :title="user_navdrawer.blog.title" :to="{ name: user_navdrawer.blog.link }"
          :icon="user_navdrawer.blog.icon" target="" class="rounded-lg">
        </nav-menu-link>

        <nav-menu-link :title="user_navdrawer.company.title" :to="{ name: user_navdrawer.company.link }"
          :icon="user_navdrawer.company.icon" target="">
        </nav-menu-link>
        <!-- <nav-menu-section-title :title="user_navdrawer.company.title" v-if="drawerCompany.loader === false">
        </nav-menu-section-title> -->
        <div class="border-slate-200 dash-nav-card mx-4 mt-2" v-if="drawerCompany.loader === false">
          <ul class="divide-gray-100 pa-2">
            <li v-for="(value, k) in drawerCompany.data" :key="k" class="dash-nav-card-item">
              <div class="flex my-auto no-wrap ">
                <v-avatar size="32">
                  <v-img :src="require('@/assets/images/bgs/profile-bnr.jpg')" alt="" height="32"
                    :lazy-src="require('@/assets/images/bgs/profile-bnr.jpg')" aspect-ratio="1"
                    class="subsecondary lighten-2 border">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-skeleton-loader type="image" height="100%" width="100%" class="mx-auto">
                        </v-skeleton-loader>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <!-- <img class="h-6 w-6 mr-2 flex-none rounded-full bg-gray-50" :src="person.imageUrl" alt="" /> -->
                <p class="ml-4 primary--text underline my-auto text-sm font-semibold text-gray-900"
                  @click="openComapy(value)">{{ value.company.name }}</p>
              </div>
            </li>
            <li class="dash-nav-card-item">
              <!-- <v-spacer></v-spacer> -->
              <router-link :to="{ name: user_navdrawer.company.link }"
                class="mr-0 leading-tight hover:underline hover:text-blue-600 inline-block text-base border-transparent">
                View all ->
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div v-else>
        <!-- <nav-menu-link :title="companie_navdrawer.home.title"
          :to="{ name: companie_navdrawer.home.link, params: { company: this.$store.getters.getCompany.name } }"
          :icon="companie_navdrawer.home.icon" target="">
        </nav-menu-link> -->

        <nav-menu-link :title="companie_navdrawer.account.title"
          :to="{ name: companie_navdrawer.account.link, params: { company: this.$store.getters.getCompany.name } }"
          :icon="companie_navdrawer.account.icon" target="">
        </nav-menu-link>

        <nav-menu-link :title="companie_navdrawer.service.title" :icon="companie_navdrawer.service.icon"
          :to="{ name: companie_navdrawer.service.link, params: { company: this.$store.getters.getCompany.name } }"
          target="">
        </nav-menu-link>

        <nav-menu-link :title="companie_navdrawer.rfp.title" :icon="companie_navdrawer.rfp.icon"
          :to="{ name: companie_navdrawer.rfp.link, params: { company: this.$store.getters.getCompany.name } }"
          target="">
        </nav-menu-link>

        <nav-menu-link :title="companie_navdrawer.rfc.title" :icon="companie_navdrawer.rfc.icon"
          :to="{ name: companie_navdrawer.rfc.link, params: { company: this.$store.getters.getCompany.name } }"
          target="">
        </nav-menu-link>

        <nav-menu-link :title="companie_navdrawer.vacancies.title" :icon="companie_navdrawer.vacancies.icon"
          :to="{ name: companie_navdrawer.vacancies.link, params: { company: this.$store.getters.getCompany.name } }"
          target="">
        </nav-menu-link>
        
        <nav-menu-link :title="companie_navdrawer.blog.title" :to="{ name: companie_navdrawer.blog.link }"
          :icon="companie_navdrawer.blog.icon" target="" class="rounded-lg">
        </nav-menu-link>

        <nav-menu-link :title="companie_navdrawer.event.title" :to="{ name: companie_navdrawer.event.link }"
          :icon="companie_navdrawer.event.icon" target="" class="rounded-lg">
        </nav-menu-link>

      </div>
      <div class="spacer-nav-menu-bottom">
        <div class=" flex flex-end  px-4 py-3 shadow" v-if="isNotCompany">
          <v-avatar size="2em" class="dash-menu-logo center mb-2">
            <v-img :src="this.$store.getters.getuserlogo" cover alt="logo-user"
              :lazy-src="this.$store.getters.getuserlogo" aspect-ratio="1" class="subsecondary lighten-2">
              <template v-slot:placeholder>
                <v-skeleton-loader type="image" height="100%" width="100%" class="mx-auto rounded-pill">
                </v-skeleton-loader>
              </template>
            </v-img>
          </v-avatar>
          <div class="ml-4 drawer-nav-menu-user-title">
            <h4 class="font-bold text-gray-800 text-lg">
              <router-link :to="{ name: user_navdrawer.account.link }">
                {{ this.$store.getters.getUser.username }}</router-link>
            </h4>
            <!-- <span class="text-gray-600">Software Engineer</span> -->

            <div class="">
              <v-card-subtitle class="text-xs pa-1" v-if="this.$store.getters.getUser.isactivated == 1">
                <v-icon size="13" class="primary--text mr-1">{{ icons.mdiCheckboxMarkedCircle }}</v-icon><span
                  class="primary--text text-caption">activated</span>
              </v-card-subtitle>

              <v-card-subtitle class="text-xs pa-0" v-else-if="this.$store.getters.getUser.isactivated == 0">
                <v-icon size="13" class="error--text mr-1">{{ icons.mdiCheckboxMarkedCircle }}</v-icon><span
                  class="error--text text-caption">unactivated</span>
              </v-card-subtitle>
              <span v-else></span>
            </div>
          </div>
        </div>
        <div class="flex flex-end  px-4 py-3 shadow secondaryAccent" v-else>
          <v-avatar size="2em" class="dash-menu-logo center mb-2">
            <v-img :src="require('@/assets/images/bgs/profile-bnr.jpg')" cover alt="logo-user"
              :lazy-src="this.$store.getters.getuserlogo" aspect-ratio="1" class="subsecondary lighten-2">
              <template v-slot:placeholder>
                <v-skeleton-loader type="image" height="100%" width="100%" class="mx-auto rounded-pill">
                </v-skeleton-loader>
              </template>
            </v-img>
          </v-avatar>
          <div class="ml-4">
            <h4 class="font-bold text-gray-800 text-lg">
              <router-link :to="{ name: companie_navdrawer.account.link }">
                {{ this.$store.getters.getCompany.name }}</router-link>
            </h4>
          </div>
        </div>
        <div class="flex flex-col pa-4" v-if="isNotCompany === false">
          <div class="mb-2">Admin</div>
          <div class=" flex flex-end">
            <v-avatar size="2em" class="dash-menu-logo center">
              <v-img :src="this.$store.getters.getuserlogo" cover alt="logo-user"
                :lazy-src="this.$store.getters.getuserlogo" aspect-ratio="1" class="subsecondary lighten-2">
                <template v-slot:placeholder>
                  <v-skeleton-loader type="image" height="100%" width="100%" class="mx-auto rounded-pill">
                  </v-skeleton-loader>
                </template>
              </v-img>
            </v-avatar>
            <div class="ml-4 drawer-nav-menu-user-title">
              <h4 class="font-bold text-gray-800 text-lg">
                <router-link :to="{ name: user_navdrawer.account.link }">
                  {{ this.$store.getters.getUser.username }}</router-link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiBriefcaseSearch,
  mdiWallet,
  mdiAccountConvert,
  mdiHome,
  mdiAccountCogOutline,
  mdiMagnify,
  mdiNewBox,
  mdiArrowLeft,
  mdiCheckboxMarkedCircle,
  mdiCloseOctagonOutline,
  mdiOfficeBuildingMarker,
  mdiAccountGroup,
  mdiViewDashboard,
  mdiCalendarEdit,
  mdiEvPlugType1,
  mdiMessageText,
  mdiForum
} from '@mdi/js'

import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },

  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    menuData: {
      type: Array,
      default: () => { },
    },
    isNotCompany: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      drawerCompany: {
        data: [],
        loader: true
      }
    }
  },
  methods: {
    openComapy(companie) {
      this.$store.commit("setCompany", {
        id: companie.id,
        about: companie.about,
        logo: companie.company.logo,
        name: companie.company.name,
        // activity_sector: companie.activity_sector,
        email: companie.email,
        // phonenumber: companie.phonenumber,
        // website: companie.website,
        // form: companie.enterprise_form,
        type: companie.type,
      });
      this.$router.push({ name: 'company.home', params: { company: companie.company.name } })
    },
  },
  beforeMount() {
    this.drawerCompany.loader = true
    Drequest.api(`lazyloading.enterprise?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}&next=1&per_page=2`)
      .get((response) => {
        if (response.success === true) {
          response.listEntity.forEach((value, index) => {
            this.drawerCompany.data.push({
              id: value.id,
              company: {
                name: value.name,
                logo: value.logo,
              },
              type: value.type.designation,
              about: value.about,
              created_at: value.created_at,
              website: value.website,
            })
          });
          this.drawerCompany.loader = false
        }
        else {
          this.drawerCompany.loader = true
        }
      })
      .catch((err) => {
        alert('Server error')
        this.drawerCompany.loader = true
      });

  },
  setup(props) {
    const icons = {
      mdiBriefcaseSearch, mdiAccountConvert, mdiWallet, mdiOfficeBuildingMarker, mdiBriefcaseSearch,
      mdiMagnify, mdiHome, mdiNewBox, mdiArrowLeft, mdiCheckboxMarkedCircle,
      mdiCloseOctagonOutline, mdiAccountGroup, mdiEvPlugType1, mdiAccountConvert, mdiMessageText,
      mdiCalendarEdit, mdiForum,
      // Dashboard
      mdiViewDashboard,
      // Deleted
      mdiAccountCogOutline
    }

    const user_navdrawer = {
        home: {
          title: 'Home',
          icon: mdiHome,
          link: 'user.home',
          divider: false
        },
        tools: {
          title: '',
          icon: mdiOfficeBuildingMarker,
          link: '',
          divider: false
        },

        company: {
          title: 'Enterprises',
          icon: mdiOfficeBuildingMarker,
          link: 'user.list.company',
          divider: false
        },
        vacancies: {
          title: 'Applications',
          icon: mdiBriefcaseSearch,
          link: 'user.vacancies.list',
          divider: false
        },
        // rfc: {
        //   title: 'Collaborations',
        //   icon: mdiAccountConvert,
        //   link: 'user.rfc',
        //   divider: false
        // },
        join: {
          title: 'Networking',
          icon: mdiAccountGroup,
          link: 'user.join.list',
          divider: false
        },
        account: {
          title: 'Home',
          // icon: mdiViewDashboard,
          icon: mdiHome,
          link: 'user.account',
          divider: false
        },

        event: {
          title: 'Events',
          icon: mdiCalendarEdit,
          link: 'user.event',
          divider: false
        },

        blog: {
          title: 'Blog',
          icon: mdiForum,
          link: 'user.blog',
          divider: false
        }
    }

    const companie_navdrawer = {
        home: {
          title: 'Home',
          icon: mdiHome,
          link: 'company.home',
        },
        service: {
          title: 'Brands & Patents',
          icon: mdiWallet,
          link: 'company.service',
        },
        rfp: {
          title: 'Call for proposal',
          icon: mdiEvPlugType1,
          link: 'company.rfp',
        },
        rfc: {
          title: 'Collaborations',
          icon: mdiAccountConvert,
          link: 'company.rfc',
        },
        vacancies: {
          title: 'Vacancies',
          icon: mdiBriefcaseSearch,
          link: 'company.vacancies',
        },
        account: {
          title: 'Home',
          // icon: mdiViewDashboard,
          icon: mdiHome,
          link: 'company.account',
        },
        blog: {
          title: 'Blog',
          icon: mdiForum,
          link: 'company.blog',
        },
        
        event: {
          title: 'Events',
          icon: mdiCalendarEdit,
          link: 'company.event',
          divider: false
        },
    }
    // c: company

    // console.log(urli)
    return {
      icons,
      companie_navdrawer,
      user_navdrawer
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/main-dash-menu.scss';

.logo-nav-drawer {
  display: flex;
  padding: 1em 2em;
  height: 4.5em;
}

.spacer-nav-menu {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 5em - 3.5em);
  //border: solid 1px #000;

  .spacer-nav-menu-bottom {
    bottom: 0;
  }



}
</style>
