<template>










  <!-- REMOVE THIS PAGE ..................... -->














  
  <div class="">
    <!-- Page heading -->
    <header class="bg-gray-50 py-8">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="min-w-0 flex-1">
          <nav class="flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div>
                  <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-700">Home</a>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- <svg class="h-5 w-5 flex-shrink-0 text-gray-400" x-description="Heroicon name: mini/chevron-right"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clip-rule="evenodd"></path>
                  </svg> -->
                  <!-- <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Engineering</a> -->
                </div>
              </li>
            </ol>
          </nav>
          <!-- <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Back End
            Developer</h1>
          <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
            <div class="mt-2 flex items-center text-sm text-gray-500">
              <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" x-description="Heroicon name: mini/briefcase"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"
                  clip-rule="evenodd"></path>
                <path
                  d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z">
                </path>
              </svg>
              Full-time
            </div>
            <div class="mt-2 flex items-center text-sm text-gray-500">
              <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" x-description="Heroicon name: mini/map-pin"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                  clip-rule="evenodd"></path>
              </svg>
              Remote
            </div>
            <div class="mt-2 flex items-center text-sm text-gray-500">
              <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                x-description="Heroicon name: mini/currency-dollar" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  d="M10.75 10.818v2.614A3.13 3.13 0 0011.888 13c.482-.315.612-.648.612-.875 0-.227-.13-.56-.612-.875a3.13 3.13 0 00-1.138-.432zM8.33 8.62c.053.055.115.11.184.164.208.16.46.284.736.363V6.603a2.45 2.45 0 00-.35.13c-.14.065-.27.143-.386.233-.377.292-.514.627-.514.909 0 .184.058.39.202.592.037.051.08.102.128.152z">
                </path>
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-6a.75.75 0 01.75.75v.316a3.78 3.78 0 011.653.713c.426.33.744.74.925 1.2a.75.75 0 01-1.395.55 1.35 1.35 0 00-.447-.563 2.187 2.187 0 00-.736-.363V9.3c.698.093 1.383.32 1.959.696.787.514 1.29 1.27 1.29 2.13 0 .86-.504 1.616-1.29 2.13-.576.377-1.261.603-1.96.696v.299a.75.75 0 11-1.5 0v-.3c-.697-.092-1.382-.318-1.958-.695-.482-.315-.857-.717-1.078-1.188a.75.75 0 111.359-.636c.08.173.245.376.54.569.313.205.706.353 1.138.432v-2.748a3.782 3.782 0 01-1.653-.713C6.9 9.433 6.5 8.681 6.5 7.875c0-.805.4-1.558 1.097-2.096a3.78 3.78 0 011.653-.713V4.75A.75.75 0 0110 4z"
                  clip-rule="evenodd"></path>
              </svg>
              $120k – $140k
            </div>
            <div class="mt-2 flex items-center text-sm text-gray-500">
              <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" x-description="Heroicon name: mini/calendar"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                  clip-rule="evenodd"></path>
              </svg>
              Closing on January 9, 2024.
            </div>
          </div> -->
        </div>
      </div>
    </header>
    <div>
      <!-- <section class="text-gray-600 body-font">
      <div class="container mx-auto flex px-4 md:flex-row flex-col items-start">
        <div class="lg:max-w-lg lg:w-full md:w-full">
          <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/200x150">
        </div>
        <div
          class="lg:flex-grow lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-start text-left">
          <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Before they sold out
          </h1>
          <p class="mb-8 leading-relaxed">Copper mug try-hard pitchfork pour-over freegan heirloom neutra
            </p>
          <div class="flex justify-center">
            <button
              class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
            <button
              class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
          </div>
        </div>
      </div>
      <hr class="black">
    </section> -->

      <!-- <div class="bg-gray-200 h-screen w-full dark:bg-gray-700 flex justify-center items-center">

      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:py-24 lg:px-8">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl dark:text-white">Our service
          statistics</h2>
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-4 mt-4">
          <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
            <div class="px-4 py-5 sm:p-6">
              <dl>
                <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">Total free
                  servers</dt>
                <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">1.6M</dd>
              </dl>
            </div>
          </div>
          <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
            <div class="px-4 py-5 sm:p-6">
              <dl>
                <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">Servers a
                  month</dt>
                <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">19.2K
                </dd>
              </dl>
            </div>
          </div>
          <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
            <div class="px-4 py-5 sm:p-6">
              <dl>
                <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">Servers a
                  week</dt>
                <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">4.9K</dd>
              </dl>
            </div>
          </div>
          <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
            <div class="px-4 py-5 sm:p-6">
              <dl>
                <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">Total users
                </dt>
                <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">166.7K
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      <!-- 
    <section class="flex flex-col justify-center max-w-6xl min-h-screen px-4 py-10 mx-auto sm:px-6">
      <div class="flex flex-wrap items-center justify-between mb-8">
        <h2 class="mr-10 text-4xl font-bold leading-none md:text-5xl">
          Continually Scale Results
        </h2>
        <a href="#"
          class="block pb-1 mt-2 text-base font-black text-blue-600 uppercase border-b border-transparent hover:border-blue-600">
          Go to insights ->
        </a>
      </div>

      <div class="flex flex-wrap -mx-4">
        <div class="w-full max-w-full mb-8 sm:w-1/2 px-4 lg:w-1/3 flex flex-col">
          <img src="https://source.unsplash.com/Lki74Jj7H-U/400x300" alt="Card img"
            class="object-cover object-center w-full h-48" />
          <div class="flex flex-grow">
            <div class="triangle"></div>
            <div class="flex flex-col justify-between px-4 py-6 bg-white border border-gray-400 text">
              <div>
                <a href="#"
                  class="inline-block mb-4 text-xs font-bold capitalize border-b-2 border-blue-600 hover:text-blue-600">Reliable
                  Schemas</a>
                <a href="#" class="block mb-4 text-2xl font-black leading-tight hover:underline hover:text-blue-600">
                  What Zombies Can Teach You About Food
                </a>
                <p class="mb-4">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla delectus corporis commodi
                  aperiam, amet cupiditate?
                </p>
              </div>
              <div>
                <a href="#"
                  class="inline-block pb-1 mt-2 text-base font-black text-blue-600 uppercase border-b border-transparent hover:border-blue-600">Read
                  More -></a>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full max-w-full mb-8 sm:w-1/2 px-4 lg:w-1/3 flex flex-col">
          <img src="https://source.unsplash.com/L9_6GOv40_E/400x300" alt="Card img"
            class="object-cover object-center w-full h-48" />
          <div class="flex flex-grow">
            <div class="triangle"></div>
            <div class="flex flex-col justify-between px-4 py-6 bg-white border border-gray-400">
              <div>
                <a href="#"
                  class="inline-block mb-4 text-xs font-bold capitalize border-b-2 border-blue-600 hover:text-blue-600">Client-based
                  Adoption</a>
                <a href="#" class="block mb-4 text-2xl font-black leading-tight hover:underline hover:text-blue-600">
                  Old School Art
                </a>
                <p class="mb-4">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla delectus.
                </p>
              </div>
              <div>
                <a href="#"
                  class="inline-block pb-1 mt-2 text-base font-black text-blue-600 uppercase border-b border-transparent hover:border-blue-600">Read
                  More -></a>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full max-w-full mb-8 sm:w-1/2 px-4 lg:w-1/3 flex flex-col">
          <img src="https://source.unsplash.com/7JX0-bfiuxQ/400x300" alt="Card img"
            class="object-cover object-center w-full h-48" />
          <div class="flex flex-grow">
            <div class="triangle"></div>
            <div class="flex flex-col justify-between px-4 py-6 bg-white border border-gray-400">
              <div>
                <a href="#"
                  class="inline-block mb-4 text-xs font-bold capitalize border-b-2 border-blue-600 hover:text-blue-600">Intellectual
                  Capital</a>
                <a href="#" class="block mb-4 text-2xl font-black leading-tight hover:underline hover:text-blue-600">
                  5 Things To Do About Rain
                </a>
                <p class="mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione, neque. Eius, ea possimus.
                </p>
              </div>
              <div>
                <a href="#"
                  class="inline-block pb-1 mt-2 text-base font-black text-blue-600 uppercase border-b border-transparent hover:border-blue-600">Read
                  More -></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
      <!-- 
    <div class="flex flex-wrap mx-auto align-center">
      <div class="d-flex items-center justify-center my-4">
        <ul class="flex list-none flex-row items-center justify-center mx-auto nav-dash-tab-ul">
          <li class="ml-0 nav-dash-tab-li text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 block leading-normal" v-on:click="toggleTabs(1)"
              v-bind:class="{ 'nav-dash-tab-item': openTab !== 1, 'nav-dash-tab-item-active': openTab === 1 }">
              Profile
            </a>
          </li>
          <li class="last:mr-0 text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 block leading-normal" v-on:click="toggleTabs(2)"
              v-bind:class="{ 'nav-dash-tab-item': openTab !== 2, 'nav-dash-tab-item-active': openTab === 2 }">
              Settings
            </a>
          </li>
          <li class="last:mr-0 text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 block leading-normal" v-on:click="toggleTabs(3)"
              v-bind:class="{ 'nav-dash-tab-item': openTab !== 3, 'nav-dash-tab-item-active': openTab === 3 }">
              Options
            </a>
          </li>
        </ul>
      </div>

      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">
              <p>
                Collaboratively administrate empowered markets via
                plug-and-play networks. Dynamically procrastinate B2C users
                after installed base benefits.
                <br />
                <br />
                Dramatically visualize customer directed convergence
                without revolutionary ROI.
              </p>
            </div>
            <div v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">
              <p>
                Completely synergize resource taxing relationships via
                premier niche markets. Professionally cultivate one-to-one
                customer service with robust ideas.
                <br />
                <br />
                Dynamically innovate resource-leveling customer service for
                state of the art customer service.
              </p>
            </div>
            <div v-bind:class="{ 'hidden': openTab !== 3, 'block': openTab === 3 }">
              <p>
                Efficiently unleash cross-media information without
                cross-media value. Quickly maximize timely deliverables for
                real-time schemas.
                <br />
                <br />
                Dramatically maintain clicks-and-mortar solutions
                without functional solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->


      <!-- <div class="grid gap-14 md:grid-cols-3 md:gap-5">
      <div class="rounded-xl bg-white p-2 text-center shadow-xl">
        <div
          class="mx-auto flex h-12 w-12 -translate-y-7 transform items-center justify-center rounded-full secondary shadow-lg shadow-teal-500/40">
          
        </div>
        <h1 class="text-darken mb-3 text-xl font-medium lg:px-14">TREE AND SHRUB PRUNING</h1>
        <p class="px-4 text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo iure inventore amet
          modi
          accusantium vero perspiciatis, incidunt dicta sed aspernatur!</p>
      </div>
      <div data-aos-delay="150" class="rounded-xl bg-white p-2 text-center shadow-xl">
        <div
          class="mx-auto flex h-16 w-16 -translate-y-12 transform items-center justify-center rounded-full shadow-lg secondary shadow-rose-500/40">
          
        </div>
        <h1 class="text-darken mb-3 text-xl font-medium lg:px-14">IRRIGATION & DRAINAGE</h1>
        <p class="px-4 text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo iure inventore amet
          modi
          accusantium vero perspiciatis, incidunt dicta sed aspernatur!</p>
      </div>
    </div> -->

      <!--  -->
      <div v-if="loader.datarfc === false">
             <!-- <h1 class="sr-only">Recent questions</h1> -->
        <ul role="list" class="space-y-4 bg-white mt-4" v-for="(valuerfc, i) in datarfc.data" :key="i">
          <li class="py-6 sm:rounded-lg sm:p-6">
            <article aria-labelledby="question-title-81614">
              <div>
                <div class="flex space-x-3">
                  <div class="flex-shrink-0">
                    <!-- <img class="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                      alt=""> -->
                  </div>
                  <div class="min-w-0 flex-1">
                    <p class="text-sm font-medium text-gray-900">
                      <a href="#" class="hover:underline">{{ valuerfc.enterprise ? valuerfc.user.username :
                          valuerfc.enterprise.name
                      }}</a>
                    </p>
                    <p class="text-sm text-gray-500">
                      <a href="#" class="hover:underline">
                        <time datetime="2020-12-09T11:43:00">{{ valuerfc.sent_at }}</time>
                      </a>
                    </p>
                  </div>
                </div>
                <h2 id="question-title-81614" class="mt-4 text-base font-medium text-gray-900">{{ valuerfc.object }}
                </h2>
              </div>
              <div class="mt-2 space-y-4 text-sm text-gray-700">
                <p v-html="valuerfc.content"></p>
              </div>
              <!-- <div class="mt-6 flex justify-between space-x-8">
                <div class="flex space-x-6">
                  <span class="inline-flex items-center text-sm">
                    <button type="button" :class="likeBtnStatus + ' filter inline-flex space-x-2'"
                      @click="onPressLikeBtn()">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/hand-thumb-up"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                          d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z">
                        </path>
                      </svg>
                      <span class="font-medium">29</span>
                      <span class="sr-only">likes</span>
                    </button>
                  </span>
                  <span class="inline-flex items-center text-sm">
                    <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/chat-bubble-left-ellipsis"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="font-medium text-gray-900">11</span>
                      <span class="sr-only">replies</span>
                    </button>
                  </span>
                  <span class="inline-flex items-center text-sm">
                    <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/eye" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
                        <path fill-rule="evenodd"
                          d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="font-medium text-gray-900">2.7k</span>
                      <span class="sr-only">views</span>
                    </button>
                  </span>
                </div>
                <div class="flex text-sm">
                  <span class="inline-flex items-center text-sm">
                    <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/share" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                          d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z">
                        </path>
                      </svg>
                      <span class="font-medium text-gray-900">Share</span>
                    </button>
                  </span>
                </div>
              </div> -->
            </article>
          </li>
        </ul>
      </div>
      <div class="flex justify-center items-center h-screen" v-else>
        <div class="rounded-full h-20 w-20 secondary animate-ping"></div>
      </div>
      <!--  -->
    </div>

  </div>
</template>

<script>
import { mdiAccountNetwork, mdiApproximatelyEqual } from '@mdi/js';
import UserCompany from '@/views/dashboard/company/UserCompany.vue';

import PricingResumeCard from '../components/PricingResumeCard.vue';
import CardStatisticRegister from '../components/CardStatisticRegister.vue';
import CardStatisticCount from '../components/CardStatisticCount.vue';

import CardDashItem from '../components/CardDashItem.vue';
import { Drequest } from '@/plugins/Drequest'

// import { GChart } from 'vue-google-charts'
import { GChart } from 'vue-google-charts/legacy'

import CardLinearChart from '@/views/dashboard/components/CardLinearChart.vue'

export default {
  components: {
    PricingResumeCard,
    CardStatisticRegister,
    CardStatisticCount,
    UserCompany,
    CardDashItem,
    CardLinearChart,

    // For graphics
    GChart,
  },
  data() {
    return {
      openTab: 1,
      likeBtn: false,
      links: [
        {
          id: '1',
          title: 'Colaborations',
          description: '',
          color: 'secondary'
        },
        {
          id: '2',
          title: 'Proposal',
          description: '',
          color: 'teal'
        },
        {
          id: '3',
          title: 'Vacancies',
          description: '',
          color: 'teal'
        }, {
          id: '4',
          title: 'Network with people',
          description: '',
          color: 'teal'
        },
      ],
      show: false,
      total_company: 0,
      networkdata: {
        title: 'Networking',
        sent: 0,
        received: 0,
        confirm: 0
      },

      rfcdata: {
        title: 'Request for colaboration',
        sent: 0,
        received: 0,
        confirm: 0
      },

      dashservices: [
        {
          title: 'Network',
          subtitle: 'View your network sumary',
          link: 'user.join.list',
          icon: this.icons.mdiAccountNetwork,
          items: []
        },
        {
          title: 'Requests for colaborations',
          subtitle: 'View your rfc sumary',
          link: 'user.rfc',
          icon: this.icons.mdiApproximatelyEqual,
          items: []
        },
      ],

      datarfc: {
        'nb_element': 0,
        'data': []
      },
      loader: {
        datarfc: true
      }
    }
  },
  computed: {
    likeBtnStatus() {
      return this.likeBtn ? 'text-green-600' : 'text-gray-400'
    },
  },
  methods: {
    onPressLikeBtn() {
      this.likeBtn = !this.likeBtn
    },

    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber
    }
  },
  beforeMount() {
    Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        const user = response.listEntity[0]
        this.$store.commit("setUser", {
          id: user.id,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          phonenumber: user.phonenumber,
          username: user.username,
          isactivated: user.is_activated,
          adress: user.adress,
          country: user.country,
          // api_key: user.api_key,
          lastlogin: user.last_login,
          logo: user.logo,
          about: user.about,
          lang: user.lang,
          username: user.username,
        });
        this.$store.commit("setToken", user.activationcode);
        this.$store.commit("setApiToken", user.api_key);
      }
      else { }
    }).catch((err) => { });

    Drequest.api(`lazyloading.enterprise?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.total_company = response.nb_element
      }
      else { }
    }).catch((err) => { });

    Drequest.api(`lazyloading.colaborator?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.networkdata.sent = response.nb_element
        // this.dashservices[0].items.push({ title: 'Sent', value: response.nb_element })
      }
      else { this.dashservices[0].items.push({ title: 'Sent', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.colaborator?dfilters=on&recipientuser:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.networkdata.received = response.nb_element
        // this.dashservices[0].items.push({ title: 'Received', value: response.nb_element }) 
      }
      else { this.dashservices[0].items.push({ title: 'Received', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.colaborator?dfilters=on&status:eq=2&user_id:eq=${this.$store.getters.getUser.id}&recipientuser:oreq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.networkdata.confirm = response.nb_element
        // this.dashservices[0].items.push({ title: 'Colaborators', value: response.nb_element })
      }
      else { this.dashservices[0].items.push({ title: 'Colaborators', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.rfcuser?dfilters=on&rfc.user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.rfcdata.sent = response.nb_element

        // console.log(response.listEntity)
        // this.dashservices[1].items.push({ title: 'Sent', value: response.nb_element }) 
      }
      else { this.dashservices[1].items.push({ title: 'Sent', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.rfc?dfilters=on&user_id:neq=${this.$store.getters.getUser.id}`).get((response) => {
      this.loader.datarfc = true
      if (response.success === true) {
        this.rfcdata.received = response.nb_element
        this.datarfc.nb_element = response.nb_element
        this.datarfc.data = response.listEntity
        // this.dashservices[1].items.push({ title: 'Received', value: response.nb_element }) 
        this.loader.datarfc = false
      }
      else {
        this.dashservices[1].items.push({ title: 'Received', value: 0 })
        this.loader.datarfc = true
      }
    }).catch((err) => { this.loader.datarfc = true});

    Drequest.api(`lazyloading.rfcuser?dfilters=on&status:eq=2&rfc.user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.rfcdata.confirm = response.nb_element
        // this.dashservices[1].items.push({ title: 'Confirmed', value: response.nb_element }) 
      }
      else {
        // this.dashservices[1].items.push({ title: 'Confirmed', value: 0 }) 
      }
    }).catch((err) => { });
  },
  setup(props) {
    const icons = { mdiAccountNetwork, mdiApproximatelyEqual }

    return {
      icons
    }
  }
}
</script>


<style lang="scss">
//@import '@/styles/styles.scss';
@import '~@/styles/main-dash-style.scss';
@import '~@/styles/utils-style.scss';

.is-red {
  background: red !important;
}

.is-blue {
  background: blue !important;
}

.outine-2 {
  border: 1px solid white;
}

.rounf {
  border: solid 1px #000 !important;
}

.learn-more-btn {
  text-transform: initial;
}

</style>
