require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#72A956',
        accent: '#171717',
        secondary: '#3079BE',
        secondaryAccent: '#FFFFFF',
        subprimary: '#CBDEEE',
        subaccent: '#EBEBEB',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#72A956',
        accent: '#171717',
        secondary: '#3079BE',
        secondaryAccent: '#FFFFFF',
        subprimary: '#CBDEEE',
        subaccent: '#EBEBEB',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  },
}