<template>
    <div class="card-dash-stat-bloc">
        <div :class="'stat-flot-content ' + (lightmode ? 'secondary' : 'primary')">
            <v-icon size="1.5em" color="secondaryAccent" class="ma-auto">{{ icons.mdiArrowVerticalLock }}</v-icon>
        </div>
        <div class="card-dash-stat-subbloc">
            <div class="subcard-row mr-4">
                <div class="subcard-icon">
                    <v-icon small :color="lightmode ? 'secondary' : 'secondaryAccent'">
                        {{ icons.mdiCallMade }}
                    </v-icon>
                </div>
                <div class="subcard-text" :class="lightmode ? 'secondary--text' : 'secondaryAccent--text'">
                    <span class="text-caption">sent</span>
                    <strong>{{ item.sent }}</strong>
                </div>
            </div>
            <div class="subcard-row">
                <div class="subcard-icon">
                    <v-icon small :color="lightmode ? 'primary' : 'secondaryAccent'">
                        {{ icons.mdiCallReceived }}
                    </v-icon>
                </div>
                <div class="subcard-text" :class="lightmode ? 'primary--text' : 'secondaryAccent--text'">
                    <span class="text-caption">Received</span>
                    <strong>{{ item.received }}</strong>
                </div>
            </div>
        </div>
        <div class="card-dash-stat-graph">
            <hr :class="(lightmode ? 'secondary' : 'primary')" />
            
            <span class="title-stat">{{ item.title }}</span>
        </div>
    </div>
</template>

<script>
import { mdiCallMade, mdiCallReceived, mdiArrowVerticalLock, mdiWrench } from '@mdi/js';
export default {
    props: {
        item: {
            type: Object,
            default: () => { }
        },
        type: {
            type: String,
            default: 'professionnal'
        },
        lightmode: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            icons: { mdiCallMade, mdiCallReceived, mdiArrowVerticalLock, mdiWrench }
        }
    },
    methods: {
        followlink() { }
    },
}
</script>


<style lang="scss" scoped>
.card-dash-stat-bloc {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 1em;
    border-radius: 1em;
    background: #FFF;
    position: relative;
    height: calc((5em - 1.5em) * 2);

    .stat-flot-content {
        position: absolute;
        top: -1.5em;
        left: 1em;
        width: 4.5em;
        height: 4.5em;
        padding: 1em;
        border-radius: 0.2em;
        z-index: 10;
        //background: #3079BE;
        color: #FFF;
        text-align: center;
    }

    .card-dash-stat-subbloc {
        margin-left: 5em;
        padding: 0px 0px 10px 0em;
    }

    .card-dash-stat-subbloc,
    .card-dash-stat-graph {
        height: calc(5em - 1em);
        position: relative;
        width: auto;
    }

    .card-dash-stat-subbloc {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;

        .card-dash-stat-subbloc-icon {
            border-radius: 100px !important;
            text-align: center;
            //padding: 0.2em;
            width: 1em !important;
            height: 1em !important;
        }

        .title-stat {
            padding: 0px 1em;
            border-radius: 0.5em;
            text-transform: uppercase !important;
            //font-weight: bold;
        }

        .subcard-row {
            display: flex;
            flex-direction: row;
            //padding: 0.5em;
            //border: solid 1px #000;

            .subcard-icon {
                //padding: 0px 0.8em !important;
                border-radius: 0.5em;
                display: flex;
                flex-direction: column;
                text-align: center;
                justify-content: center;
            }

            .subcard-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 0.3em;
            }
        }
    }

    .subcard-graph {
        margin-left: 1em;
        align-items: center;
        vertical-align: center;
        justify-items: center;
    }

}
</style>
