<template>
  <div class="pa-2 space-y-8">
    <!-- Header Section -->
    <header class="border-gray-300 shadow bg-white">
      <div class="com-head-profile">
        <div class="com-head-cover">
        </div>
        <div class="com-head-logo">
          <v-avatar size="100%" rounded="0">
            <!-- Company logo here -->
            <v-img :src="require('@/assets/images/avatars/img-xl.svg')" alt="logo-user">
            </v-img>
          </v-avatar>
        </div>
      </div>
      <div class="px-4" v-if="loader.company == false">
        <div class="headline">
          {{ company.name }}
        </div>
        <div>
          <!-- {{comapny.activity_sector}} -->
          {{ company.about }}
        </div>
        <div class="d-flex flex-row">
          <v-icon small>
            {{ icons.mdiMapMarkerOutline }}
          </v-icon>
          <span class="text-caption"> {{ company.country.name }}</span>
        </div>
        <div>
          <button class="bg-green-600 text-white px-4 py-1 rounded-lg">
            Send RFC
          </button>
        </div>
      </div>

      <div class="flex flex-wrap mx-auto">
        <div class="p-2 md:w-1/3">
          <div class="flex rounded-lg h-full bg-gray-100 p-4 flex-col">
            <h2 class="font-bold text-lg mb-2 pa-4">Adresse</h2>
            <p><strong>Immeuble:</strong> Ffffffffffffffff</p>
            <p><strong>BP:</strong> 3000 Douala, Cameroon 🇨🇲</p>
          </div>
        </div>
        <div class="p-2 md:w-1/3">
          <div class="flex rounded-lg h-full bg-gray-100 p-4 flex-col">
            <div>
              <span class="font-bold">Telephone:</span> 00237 9777555, 00237 7765432
            </div>
            <div class="d-flex flex-row justify-space-between">
              <div>
                <span class="font-bold">Email:</span>
                <a href="mailto:contact@alain.com" class="text-blue-500 underline">contact@alain.com</a>
              </div>

            </div>
          </div>
        </div>
        <div class="p-2 md:w-1/3">
          <div class="flex rounded-lg h-full bg-gray-100 p-4 flex-col">
            <p class="font-semibold">Ownership:</p>
            <div class="p-2">
              <span class="font-semibold">Local (country of immatriculation):</span> 60%
            </div>
            <div class="p-2">
              <span class="font-semibold">Other African country:</span> 30%
            </div>
            <div class="p-2">
              <span class="font-semibold">Non African:</span> 10%
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Products Section -->
    <section class="pa-4 border-gray-300 shadow bg-white">
      <h2 class="font-bold text-lg mb-2">Products</h2>
      <div class="grid grid-cols-3 gap-4">
        <div v-for="(product, index) in products" :key="index" class="border p-2 rounded text-center bg-gray-50 shadow">
          <img :src="product.image" alt="" class="h-16 w-16 mx-auto mb-2">
          <p>{{ product.name }}</p>
          <button class="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
            @click="viewImage(product.image)">View</button>
          <button class="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
            @click="editProduct(index)">Edit</button>
          <button class="bg-red-500 text-white px-2 py-1 mt-2 rounded hover:bg-red-600"
            @click="deleteProduct(index)">X</button>
        </div>
      </div>
      <div class="mt-4">
        <input v-model="newProduct.name" placeholder="Nom du produit" class="border p-2 mr-2 rounded" />
        <input v-model="newProduct.image" placeholder="URL de l'image" class="border p-2 mr-2 rounded" />
        <button class="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600" @click="addProduct">Add
          +</button>
      </div>
    </section>

    <!-- Services Section -->
    <section class="p-4 border-gray-300 shadow bg-white">
      <h2 class="font-bold text-lg mb-2">Services</h2>
      <div class="grid grid-cols-3 gap-4">
        <div v-for="(service, index) in services" :key="index" class="border p-2 rounded text-center bg-gray-50 shadow">
          <img :src="service.image" alt="" class="h-16 w-16 mx-auto mb-2">
          <p>{{ service.name }}</p>
          <button class="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
            @click="viewImage(service.image)">View</button>
          <button class="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
            @click="editService(index)">Edit</button>
          <button class="bg-red-500 text-white px-2 py-1 mt-2 rounded hover:bg-red-600"
            @click="deleteService(index)">X</button>
        </div>
      </div>
      <div class="mt-4">
        <input v-model="newService.name" placeholder="Nom du service" class="border p-2 mr-2 rounded" />
        <input v-model="newService.image" placeholder="URL de l'image" class="border p-2 mr-2 rounded" />
        <button class="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600" @click="addService">Add
          +</button>
      </div>
    </section>

    <!-- Vacancies Section -->
    <section class="p-4 border-gray-300 shadow bg-white">
      <h2 class="font-bold text-lg mb-2">Vacancies</h2>
      <ul class="space-y-2">
        <li v-for="(vacancy, index) in vacancies" :key="index" class="mb-2">
          <a href="#" class="text-blue-500 underline">{{ vacancy.title }}</a>
          <button class="bg-blue-500 text-white px-2 py-1 ml-2 rounded hover:bg-blue-600"
            @click="viewVacancy(index)">View</button>
          <button class="bg-yellow-500 text-white px-2 py-1 ml-2 rounded hover:bg-yellow-600"
            @click="editVacancy(index)">Edit</button>
          <button class="bg-red-500 text-white px-2 py-1 ml-2 rounded hover:bg-red-600"
            @click="deleteVacancy(index)">X</button>
        </li>
      </ul>
      <button class="bg-blue-500 text-white px-4 py-2 mt-4 rounded shadow hover:bg-blue-600" @click="addVacancy">Add
        +</button>
    </section>

    <!-- Proposals Section -->
    <section class="p-4 border-gray-300 shadow bg-white">
      <h2 class="font-bold text-lg mb-2">Request for Proposals</h2>
      <ul class="space-y-2">
        <li v-for="(proposal, index) in proposals" :key="index" class="mb-2">
          <a href="#" class="text-blue-500 underline">{{ proposal.title }}</a>
          <button class="bg-blue-500 text-white px-2 py-1 ml-2 rounded hover:bg-blue-600"
            @click="viewProposal(index)">View</button>
          <button class="bg-yellow-500 text-white px-2 py-1 ml-2 rounded hover:bg-yellow-600"
            @click="editProposal(index)">Edit</button>
          <button class="bg-red-500 text-white px-2 py-1 ml-2 rounded hover:bg-red-600"
            @click="deleteProposal(index)">X</button>
        </li>
      </ul>
      <button class="bg-blue-500 text-white px-4 py-2 mt-4 rounded shadow hover:bg-blue-600" @click="addProposal">Add
        +</button>
    </section>
  </div>
</template>

<script>
import { mdiMapMarkerOutline, mdiWeb, mdiArrowLeft } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
export default {
  data() {
    return {

      company: {},
      loader: {
        company: true
      },

      products: [
        { name: 'Bananas', image: 'https://via.placeholder.com/150' },
        { name: 'Avocados', image: 'https://via.placeholder.com/150' },
        { name: 'Papaya', image: 'https://via.placeholder.com/150' },
      ],
      newProduct: { name: '', image: '' },
      services: [
        { name: 'Development web', image: 'https://via.placeholder.com/150' },
        { name: 'Trading et change', image: 'https://via.placeholder.com/150' },
        { name: 'Controle fiduciere', image: 'https://via.placeholder.com/150' },
      ],
      newService: { name: '', image: '' },
      vacancies: [
        { title: 'Agent de maitrise Bacc+2' },
      ],
      newVacancy: { title: '' },
      proposals: [
        { title: 'Forage a Bangui' },
      ],
      newProposal: { title: '' },
    }
  },
  beforeMount() {
    console.log(this.$route.params)

    this.fetchData()
    // this.$watch(
    //   () => this.$route.params,
    //   () => {
    //     this.fetchData()
    //   },
    //   { immediate: true }
    // )
  },
  methods: {
    fetchData() {
      this.loader.company = true
      Drequest.api(`lazyloading.enterprise?dfilters=on&enterprise.id:eq=${this.$route.params.ids}&next=1&per_page=1`)
        .get((response) => {
          if (response.success === true) {
            this.company = response.listEntity[0]
            console.log(this.company)
            this.loader.company = false
          }
          else {
            this.loader.company = false
          }
        })
        .catch((err) => {
          this.loader.company = false
        });
    },
    // Products Methods
    addProduct() {
      if (this.newProduct.name && this.newProduct.image) {
        this.products.push({ ...this.newProduct });
        this.newProduct = { name: '', image: '' };
      }
    },
    deleteProduct(index) {
      this.products.splice(index, 1);
    },
    editProduct(index) {
      const updatedName = prompt('Modifier le nom du produit:', this.products[index].name);
      const updatedImage = prompt('Modifier l\'URL de l\'image:', this.products[index].image);
      if (updatedName) this.products[index].name = updatedName;
      if (updatedImage) this.products[index].image = updatedImage;
    },
    viewImage(imageUrl) {
      window.open(imageUrl, '_blank');
    },
    // Services Methods
    addService() {
      if (this.newService.name && this.newService.image) {
        this.services.push({ ...this.newService });
        this.newService = { name: '', image: '' };
      }
    },
    deleteService(index) {
      this.services.splice(index, 1);
    },
    editService(index) {
      const updatedName = prompt('Modifier le nom du service:', this.services[index].name);
      const updatedImage = prompt('Modifier l\'URL de l\'image:', this.services[index].image);
      if (updatedName) this.services[index].name = updatedName;
      if (updatedImage) this.services[index].image = updatedImage;
    },
    // Vacancies Methods
    addVacancy() {
      const newTitle = prompt('Ajouter un titre pour la nouvelle offre:');
      if (newTitle) this.vacancies.push({ title: newTitle });
    },
    deleteVacancy(index) {
      this.vacancies.splice(index, 1);
    },
    editVacancy(index) {
      const updatedTitle = prompt('Modifier le titre de l\'offre:', this.vacancies[index].title);
      if (updatedTitle) this.vacancies[index].title = updatedTitle;
    },
    viewVacancy(index) {
      alert('Visualiser les détails de l\'offre : ' + this.vacancies[index].title);
      // Code pour ouvrir une nouvelle page avec les détails
    },
    // Proposals Methods
    addProposal() {
      const newTitle = prompt('Ajouter un titre pour la nouvelle proposition:');
      if (newTitle) this.proposals.push({ title: newTitle });
    },
    deleteProposal(index) {
      this.proposals.splice(index, 1);
    },
    editProposal(index) {
      const updatedTitle = prompt('Modifier le titre de la proposition:', this.proposals[index].title);
      if (updatedTitle) this.proposals[index].title = updatedTitle;
    },
    viewProposal(index) {
      alert('Visualiser les détails de la proposition : ' + this.proposals[index].title);
      // Code pour ouvrir une nouvelle page avec les détails
    },
  },
  setup() {
    const icons = {
      mdiMapMarkerOutline, mdiWeb, mdiArrowLeft
    }
    return {
      icons
    }
  },
};
</script>

<style scoped lang="scss">
/* Add custom styles to refine the look */
button {
  transition: background-color 0.3s ease;
}

.com-head-profile {
  position: relative;
  margin-bottom: calc(3.5em + 1em); // avatar size + card margin
  //border-radius: 1em !important;

  .com-head-cover {
    position: relative;
    height: 7em !important;
    //border-radius: 1em !important;
    //background: var(--v-primary-base);
    // background: -webkit-linear-gradient(90deg, var(--v-primary-base) -30%, rgba(72, 149, 236, 0.651) 100%);
  }

  .com-head-logo {
    height: 7em;
    width: 7em;
    bottom: calc(-3.5em + 3px); // [height/2] + [border/2]
    left: 1em;
    top: 3.5em;
    position: absolute;
    z-index: 4;
  }
}
</style>
