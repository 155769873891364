<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content"
    transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">

      <!-- <div class="h-full xl:flex items-center justify-center"> -->
      <div class="mr-3" v-bind="attrs" v-on="on">
        <!-- <div class="w-full h-full flex justify-center items-center"> -->
          <button
            class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 rounded flex items-center">
            <v-avatar size="2.2em" v-bind="attrs" v-on="on">
              <v-img :src="$store.getters.getuserlogo" :lazy-src="$store.getters.getuserlogo" aspect-ratio="1"
                class="subsecondary lighten-2" v-if="isNotCompany === true">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                      class="mx-auto rounded-pill">
                    </v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
              <v-img :src="require('@/assets/images/avatars/profil.svg')"
                :lazy-src="require('@/assets/images/avatars/profil.svg')" aspect-ratio="1"
                class="subsecondary lighten-2" v-if="isNotCompany === false">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                      class="mx-auto rounded-pill">
                    </v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <p class="hidden-sm-and-down text-gray-800 text-sm ml-2 my-auto">{{ isNotCompany !== false ?
                $store.getters.getUser.username : $store.getters.getCompany.name
            }}</p>
          </button>
        <!-- </div> -->
      </div>
      <!-- </div> -->
    </template>

    <!-- transition="slide-y-transition" -->

    <v-list>
      <div class="" style="cursor: pointer" @click="navigateToLink('professional', 'user.account')">
        <v-badge bottom color="primary" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="35px">
            <v-img :src="this.$store.getters.getuserlogo"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align:middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ this.$store.getters.getUser.username }}
          </span>
          <small class="text--disabled text-capitalize">professional</small>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title @click="isNotCompany !== false ? navigateToLink('professional', 'user.account.profile'): navigateToLink('company', 'company.setting')">Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <div class="mb-2" style="cursor: pointer" @click="navigateToLink('company', 'company.setting')"
        v-if="this.$store.getters.getCompany.name">
        <v-badge bottom color="primary" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="35px">
            <v-img :src="require('@/assets/images/avatars/profil.svg')"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align:middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ this.$store.getters.getCompany.name }}
          </span>
          <small class="text--disabled text-capitalize">Company</small>
        </div>
      </div>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title @click="navigateToLink('professional', 'user.list.company')">Enterprises
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-0"></v-divider>

      <!-- Settings -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click="navigateToLink('professional', 'user.account.setting')">Settings
          </v-list-item-title>
          <!-- <v-list-item-title @click="navigateToLink('user.account')">Settings</v-list-item-title> -->
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-0"></v-divider>

      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click="logoutUser()">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

import { Drequest } from '@/plugins/Drequest'

export default {
  props: {
    isNotCompany: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      companyId: this.$store.getters.getCompany.id,
    }
  },
  methods: {
    navigateToLink(user, value) {
      if (user === 'company') {
        this.$router.push({ name: value.toString(), params: { company: this.$store.getters.getCompany.name } })
      } else {
        this.$router.push({ name: value.toString() })
      }
    },
    logoutUser: function (e) {
      const promise = this.$store.dispatch('logout');
      promise.then((res) => {
        // window.console.log(res);
        this.$router.push('/');
      })
        .catch(err => {
          window.console.log(err)
        })
      //this.loading = false
    },

    loadProfile: function (e) {
      Drequest.api(`user.loadlogo?user_id=${this.$store.getters.getUser.id}`).get((response) => {
        if (response.success === true) {
          this.$store.commit("setuserlogo", response.logo);
        }
        else { }
      }).catch((err) => { });
    },
  },
  beforeMount() {
    this.loadProfile()
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
