import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import '@/assets/css/tailwind.css'
import VueSimpleAlert from "vue-simple-alert";

// import {SplitCarousel} from "vue-split-carousel";

import SplitCarousel from "vue-split-carousel";
import "vue-split-carousel/dist/vue-split-carousel.css";


Vue.config.productionTip = false
Vue.use(SplitCarousel);

Vue.use(VueSimpleAlert);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate() {
    this.$store.commit('initialiseStore')
  }
}).$mount('#app')