
<template>
    <v-card height="100%" class="h-full rounded-card-dash d-flex flex-column align-center rounded-lg" flat v-if="item">
        <div class="subsecondary w-full text-center pa-4">
            <v-avatar flat tile class="mx-auto">
                <v-icon color="secondary">{{ item.icon }}</v-icon>
            </v-avatar>
        </div>
        <v-card-title class="text-center text-wrap">
            <span>{{ item.title }}</span>
        </v-card-title>
        <v-card-subtitle>
            <span class="text-caption text-no-wrap">{{ item.subtitle }}</span>
        </v-card-subtitle>
        <v-card-actions>
            <v-btn small outlined color="secondary" class="subsecondary px-4" @click="followlink(item.link)">View more
            </v-btn>
        </v-card-actions>
        <v-spacer></v-spacer>
        <v-divider width="100%"></v-divider>
        <v-card-text class="d-flex flex-row flex-wrap justify-center" v-if="item.items">
            <div class="d-flex flex-column mx-auto align-center" v-for="obj in item.items">
                <span class="font-weight-bold">{{ obj.value }}</span>
                <span class="text-caption">{{ obj.title }}</span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mdiArrowLeftThinCircleOutline } from '@mdi/js';
export default {
    props: {
        item: {
            type: Object,
            default: () => { }
        },
        dashboard: {
            type: String,
            default: 'user'
        }
    },
    data() {
        return {
            user: 'user',
            company: 'company',
            icons: { mdiArrowLeftThinCircleOutline }
        }
    },
    methods: {
        followlink(value) {
            if(this.dashboard === this.user){this.$router.push({ name: value })}
            if(this.dashboard === this.company){this.$router.push({ name: value, params: { company: this.$store.getters.getCompany.name }})}
        }
    },
}
</script>


<style lang="scss" scoped>
.rounded-card-dash {
    border: 1px solid rgb(214, 213, 213);
}
</style>
