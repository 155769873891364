<template>
    <div class="grad-bg-color price-resum-card">
        <v-col cols="12" md="4" class="price-resum-pricing">
            <div class="title mb-4">Pricing</div>
            <div class="subtext">
                <span class="col-4">Basic formule</span>
                <span class="value">Free</span>
            </div>
            <div class="subtext">
                <span class="col-4">Penium formule</span>
                <span class="value">$50</span>
            </div>
            <div class="subtext">
                <span class="col-4">Platine formule</span>
                <span class="value">$70</span>
            </div>
            <div class="subtext">
                <span class="col-4">Gold formule</span>
                <span class="value">$100</span>
            </div>
        </v-col>
        <v-col cols="12" md="" class="price-resum-subcard">
            <v-card class="">
                <!-- <v-card-text class="px-4"> -->
                <v-col cols="12" md="5" class="subcard-contain" v-if="datas.company">
                    <div class="d-flex flex-row align-left">
                        <div class="subcard-ball secondary"></div>
                        <h1 class="secondary--text ml-4">{{datas.company.nb_element}}</h1>
                    </div>
                    <h4 class="secondary-text">{{datas.company.title}}</h4>
                </v-col>
                <v-col cols="12" md="7" class="subcard-text">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                </v-col>
                <!-- </v-card-text> -->
            </v-card>
            <v-card class="mt-10">
                <v-card-text class="px-4">
                    <v-col cols="12" md="5" class="subcard-contain" v-if="datas.user">
                        <div class="d-flex flex-row align-center">
                            <div class="subcard-ball primary"></div>
                            <h1 class="primary--text ml-4">{{datas.user.nb_element}}</h1>
                        </div>
                        <h4 class="primary-text">{{datas.user.title}}</h4>
                    </v-col>
                    <v-col cols="12" md="7" class="subcard-text">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                    </v-col>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import { mdiArrowLeftThinCircleOutline } from '@mdi/js';

export default {
    props: {
        item: {
            type: Object,
            default: () => { }
        },
        dashboard: {
            type: String,
            default: 'user'
        },
        datas: {
            type: Object,
            default: {
                company: { title: "COMPANIES", nb_element: 0 },
                user: { title: "PROFESSIONALS", nb_element: 0 }
            }
        }
    },
    data() {
        return {
            icons: { mdiArrowLeftThinCircleOutline }
        }
    },
    methods: {
        followlink() { }
    },
}
</script>


<style lang="scss" scoped>
.price-resum-card {
    display: flex;
    flex-direction: row;
    border-radius: 1em;
    padding: 1em;

    .price-resum-pricing {
        width: 50%;
        display: flex;
        flex-direction: column;

        .title {
            color: var(--v-secondaryAccent-base);
        }

        .subtext {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            color: var(--v-secondaryAccent-base);
        }

        .value {
            font-weight: bold;
            color: var(--v-secondaryAccent-base);
        }
    }

    .price-resum-subcard {
        .subcard-contain {
            display: flex;
            flex-direction: column;

            .subcard-ball {
                width: 2em;
                height: 2em;
                border-radius: 5em;
            }
        }
    }
}
</style>
