<template>
    <div :class="'register-stat-card ' + (darkmode ? 'secondaryAccent' : 'secondary')" flat>
        <h4 :class="'title ' + (darkmode ? 'secondary--text' : 'secondaryAccent--text')">
            ALL {{ type === 'Professionals' ? 'CONNECTIONS' : 'REGISTERED ' }}<br />{{ type }}</h4>
        <v-spacer></v-spacer>
        <hr />
        <div class="subcard-contain">
            <div class="subcard-text">
                <h3 :class="darkmode ? 'primary--text' : 'secondaryAccent--text'">{{ data }}</h3>
                <h4 :class="darkmode ? 'accent--text' : 'secondaryAccent--text'">{{ type }}</h4>
            </div>

            <div class="subcard-graph">
                Sed ut perspiciatis unde omnis iste natus
                Sed ut perspiciatis unde omnis iste natus
            </div>
        </div>
    </div>
</template>

<script>
import { mdiArrowLeftThinCircleOutline } from '@mdi/js';
export default {
    props: {
        item: {
            type: Object,
            default: () => { }
        },
        type: {
            type: String,
            default: 'professionnal'
        },
        data: {
            type: Number,
            default: 0
        },
        darkmode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            icons: { mdiArrowLeftThinCircleOutline }
        }
    },
    methods: {
        followlink() { }
    },
}
</script>


<style lang="scss" scoped>
.register-stat-card {
    width: auto;
    padding: 1em;
    border-radius: 1em;
    display: flex;
    flex-direction: column;

    .title {
        text-transform: uppercase !important;
        font-weight: bold;
    }

    .subcard-contain {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .subcard-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .subcard-graph {
        align-items: center;
        vertical-align: center;
        justify-items: center;
    }

}
</style>
