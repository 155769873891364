<template>
    <v-menu offset-y center nudge-bottom="14" min-width="230" content-class="sm-header-nav-items"
        transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-avatar size="2.2em" class="btn-nav-item" v-bind="attrs" v-on="on">
                <v-btn fab center @click="isClicked = !isClicked" color="primary">
                    <v-icon color="white" align="center" justify="center">
                        {{ icons.mdiMenu }}
                    </v-icon>
                </v-btn>
            </v-avatar>
        </template>
        <!-- <v-expand-transition> -->
        <v-list class="align-center">
            <v-list-item link v-for="(item, i) in navbarData" :key="i" class="sm-list-item">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ item.icon }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title @click="navigateToLink(item.link)">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>

import {
    mdiMenu, mdiClose,
} from '@mdi/js'



export default {
    props: {
        navbarData: {
            type: Array,
            default: () => { },
        }, default: () => { },
    },
    data() {
        return {
            isClicked: false
        }
    },
    methods: {
        navigateToLink(value) {
            this.$router.push({ name: value.toString() })
        }
    },
    setup() {
        return {
            icons: {
                mdiMenu, mdiClose,
            },
        }
    },
}
</script>

<style lang="scss">
.sm-header-nav-items {
    .v-list-item {
        min-height: 2.2rem !important;
    }
}

.btn-nav-item {
    border-radius: 10rem !important;
    //border: solid 1px var(--v-secondary-base);
    border: none;
}


.v-list-item-title:hover {
    color: var(--v-secondary-base) !important;
}
</style>
