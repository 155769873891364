<template>
  <v-container floating class="app-navigation-menu py-0">
    <!-- Navigation Header -->
    <div class="vertical-nav-dash py-8">
      <v-col class="d-flex flex-column justify-center align-center pb-4">

        <h3 class="mt-4"></h3>
        <h4>{{null}}</h4>
         <v-card-subtitle class="text-xs pa-0">
            <v-icon size="13" class="primary--text mr-1">
            <!-- {{icons.mdiCheckboxMarkedCircle}} -->
            </v-icon><span class="primary--text text-caption"></span>
          </v-card-subtitle>

         <v-card-subtitle class="text-xs pa-0" >
            <v-icon size="13" class="error--text mr-1">
            <!-- {{icons.mdiCheckboxMarkedCircle}} -->
            </v-icon><span class="error--text text-caption"></span>
          </v-card-subtitle>
          <span></span>
      </v-col>

      <v-divider></v-divider>

      <v-list expand shaped class="vertical-nav-menu-items pr-5 py-10">
        
      </v-list>
    </div>
  </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDomain,
  mdiHomeOutline,
  mdiAccountCogOutline,
  mdiCollapseAllOutline,
  mdiCollapseAll,
  mdiMagnify,
  mdiPencilOutline,
  mdiArrowLeft,
  mdiCheckboxMarkedCircle, 
  mdiCloseOctagonOutline,
  mdiBallotOutline
} from '@mdi/js'

import { ref } from '@vue/composition-api'

export default {
  components: {
  },
  props: {

  },
    //   beforeMount() {
    //     if (this.$store.getters.getUserId) {
    //         console.log(this.$store.getters.getCompany)
    //     }
    //     else {
    //         this.$router.push({ name: 'login' })
    //     }
    // },
  setup(props) {
    // const menuDatas = props.menuData
    const icons = {
      mdiDomain, mdiBallotOutline, mdiAccountCogOutline, mdiCollapseAllOutline, mdiCollapseAll,
      mdiMagnify, mdiHomeOutline, mdiPencilOutline, mdiArrowLeft, mdiCheckboxMarkedCircle, 
      mdiCloseOctagonOutline
    }

    const menusProf = {
      home: {
        title: 'Home',
        icon: mdiHomeOutline,
        link: 'professional-home',
      },
      account: {
        title: 'My account',
        icon: mdiAccountCogOutline,
        link: 'professional-account',
      },
      company: {
        title: 'My companies',
        icon: mdiBallotOutline,
        link: 'company-list',
      },
      createcomp: {
        title: 'Create company',
        icon: mdiPencilOutline,
        link: 'company-create',
      },
      join: {
        title: 'Join',
        icon: mdiPencilOutline,
        link: 'company-create',
      },
      rfp: {
        title: 'Call for proposal',
        icon: mdiCollapseAllOutline,
        group: {
          new: {
            title: 'New rfp',
            icon: mdiPencilOutline,
            link: 'prof-new-rfp',
          },
          inbox: {
            title: 'Received rfp/reoi',
            icon: mdiArrowLeft,
            link: 'prof-rfp',
          },
          publish: {
            title: 'Sent rfp/reoi',
            icon: mdiArrowLeft,
            link: 'prof-rfp-pub',
          },
        }
      },
    }
 
    // console.log(urli)
    return {
      icons,
      menusProf,
    }
  },
}
</script>